import React from "react";

export const getDir = (content) => {
  if ((content >= "א" && content <= "ת") || content === "[") {
    return "rtl";
  }

  return "ltr";
};

export const getPictureOfChat = (chat, styles = {}) => {
  return <img className={styles} src="images/terrazone.png" />;
};

export const isChatAtBottom = () => {
  const chatBody = document.querySelector("#chat-body");

  return chatBody.scrollHeight - chatBody.scrollTop === chatBody.clientHeight;
};

export const chatToBottom = (ifBottom = false) => {
  const chatBody = document.querySelector("#chat-body");

  if (
    ifBottom &&
    chatBody.scrollHeight - chatBody.scrollTop !== chatBody.clientHeight
  ) {
    return;
  }

  if (chatBody) {
    chatBody.scrollTop = Number.MAX_SAFE_INTEGER;
  }
};
