import produce from "immer";

const SET_CHATS = "SET_CHATS";
const SET_CHAT = "SET_CHAT";
const SET_CONTACTS = "SET_CONTACTS";
const SET_MESSAGES = "SET_MESSAGES";
const SET_MESSAGES_IN_CHAT = "SET_MESSAGES_IN_CHAT";
const UPDATE_TIMESTAMP_OF_CHAT = "UPDATE_TIMESTAMP_OF_CHAT";
const ADD_TO_MESSAGES = "ADD_TO_MESSAGES";
const ADD_MANY_TO_MESSAGES = "ADD_MANY_TO_MESSAGES";

export default function reducer(state = {}, action) {
  switch (action.type) {
    case SET_CHATS: {
      return produce(state, (draftState) => {
        draftState[action.device] = draftState[action.device] || {};
        draftState[action.device].chats = action.chats;
      });
    }

    case SET_CHAT: {
      return produce(state, (draftState) => {
        draftState[action.device] = draftState[action.device] || {};
        draftState[action.device].chats = draftState[action.device].chats || [];

        const idx = draftState[action.device].chats.findIndex(
          (chat) => chat.id._serialized === action.chat.id._serialized
        );

        action.chat.device = action.device;

        if (idx >= 0) {
          action.chat.messages = draftState[action.device].chats[idx].messages;

          draftState[action.device].chats[idx] = action.chat;
        } else {
          action.chat.messages = [];

          draftState[action.device].chats.push(action.chat);
        }
      });
    }

    case SET_CONTACTS: {
      return produce(state, (draftState) => {
        draftState[action.device].contacts = action.contacts;
      });
    }

    case SET_MESSAGES: {
      return produce(state, (draftState) => {
        draftState[action.device] = draftState[action.device] || {};
        draftState[action.device].messages =
          draftState[action.device].messages || {};
        draftState[action.device].messages[action.chatID] = action.messages;
      });
    }

    case SET_MESSAGES_IN_CHAT: {
      return produce(state, (draftState) => {
        draftState[action.device].messages[action.chatID] = action.messages;
      });
    }

    case ADD_TO_MESSAGES: {
      return produce(state, (draftState) => {
        draftState[action.device].messages[action.chatID].push(action.message);
      });
    }

    case ADD_MANY_TO_MESSAGES: {
      return produce(state, (draftState) => {
        draftState[action.device].messages[action.chatID].push(
          ...action.messages
        );
      });
    }

    case UPDATE_TIMESTAMP_OF_CHAT: {
      return produce(state, (draftState) => {
        const chatObj = draftState[action.device].chats.find(
          (item) => item.id._serialized === action.chatID
        );

        if (chatObj) {
          chatObj.timestamp = +new Date() / 1000;
        }
      });
    }

    default:
      return state;
  }
}

export const setChats = (device, chats) => {
  return { type: SET_CHATS, device, chats };
};

export const setChat = (device, chat) => {
  return { type: SET_CHAT, device, chat };
};

export const setContacts = (device, contacts) => {
  return { type: SET_CONTACTS, device, contacts };
};

export const setMessages = (device, chatID, messages) => {
  return { type: SET_MESSAGES, device, chatID, messages };
};

export const setMessagesInChat = (device, chatID, messages) => {
  return { type: SET_MESSAGES_IN_CHAT, device, chatID, messages };
};

export const updateTimestampOfChat = (device, chatID) => {
  return { type: UPDATE_TIMESTAMP_OF_CHAT, device, chatID };
};

export const addToMessages = (device, chatID, message) => {
  return { type: ADD_TO_MESSAGES, device, chatID, message };
};

export const addManyToMessages = (device, chatID, messages) => {
  return { type: ADD_MANY_TO_MESSAGES, device, chatID, messages };
};
