import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import MainRouter from "./pages";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        window.serverUrl = process.env.REACT_APP_SERVER_URL;

        if (Notification.permission !== "granted") {
            Notification.requestPermission();
        }

        setMounted(true);
    }, []);

    if (!mounted) {
        return null;
    }

    return (
        <BrowserRouter>
            <MainRouter />
            <ToastContainer
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='colored'
            />
        </BrowserRouter>
    );
}

export default App;
