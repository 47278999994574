import React, { useState } from "react";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";
import { KeyDetector } from "@dreamvps/dream-ui/dist";
import Logo from "../../../asset/Images/Logo.png";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { Button } from "reactstrap";

export default function AdminLogin() {
  const ajax = useAjax();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(null);

  async function handleLoginClicked() {
    const _email = email.trim();
    const _password = password.trim();

    setError(null);

    if (!_email || !_password) {
      return setError("Please fill all fields");
    }

    const data = await ajax("/user/login", {
      email: _email,
      password: _password,
      role: "admin",
    });

    if (data.result === "success") {
      location.href = "/admin/usermanagement";
    } else {
      setError("Invalid email or password");
    }
  }

  return (
    <div style={{ background: "white", width: "100%" }}>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link rel="stylesheet" type="text/css" href="assets/css/style.css" />
      <section className="login-terra">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="login-inner">
                <div className="login-inner-img">
                  <img src={Logo} />
                </div>

                <div className="login-inner-form">
                  <KeyDetector
                    keyCode={13}
                    onKeyDetected={handleLoginClicked}
                    className="input-email"
                  >
                    <EmailIcon className="emailicon" />
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </KeyDetector>
                  <KeyDetector
                    keyCode={13}
                    onKeyDetected={handleLoginClicked}
                    className="input-password"
                  >
                    <LockIcon className="emailicon" />
                    <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </KeyDetector>
                  {error && (
                    <div className="error" style={{ marginTop: "10px" }}>
                      {error}
                    </div>
                  )}
                  <Button
                    className="login-inner-btn"
                    type="submit"
                    onClick={handleLoginClicked}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
