const SET_USER = "SET_USER";

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER: {
      return action.data;
    }
    default: {
      return state;
    }
  }
}

export const setUser = (data) => {
  return { type: SET_USER, data };
};
