import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Button,
  Col,
} from "reactstrap";
import { IconButton, CustomText } from "@dreamvps/dream-ui/dist";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";
import { useSelector } from "react-redux";
const roles = [
  { label: "Admin", value: "admin" },
  { label: "User", value: "user" },
];

function AddUserModal({ isOpen, onClose, edit }) {
  const ajax = useAjax();
  const { user } = useSelector((state) => ({
    user: state.user,
  }));

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (edit) {
        setEmail(edit.email);
        setPassword("");
        setFirstName(edit.first_name);
        setLastName(edit.last_name);
        setRole(roles.find((item) => item.value === edit.role));
      } else {
        setEmail("");
        setPassword("");
        setFirstName("");
        setLastName("");
        setRole(roles[0]);
      }
      setError(null);
      setLoading(false);
    }
  }, [edit, isOpen]);

  async function handleAddClicked() {
    const _email = email.trim();
    const _password = password.trim();
    const _firstName = firstName.trim();
    const _lastName = lastName.trim();

    setError(null);

    if (!_email || (!edit && !_password) || !_firstName || !_lastName) {
      return setError("Please fill all fields");
    }

    setLoading(true);

    await ajax(edit ? "/user/edit" : "/user/add", {
      email: _email,
      password: _password,
      firstName: _firstName,
      lastName: _lastName,
      editUserID: edit?.id,
      role: role.value,
    });

    setLoading(false);

    onClose(true);
  }

  return (
    <div
      style={{
        marginTop: "50px",
        zIndex: 100,
        margin: "12.75rem auto !important",
      }}
    >
      <Modal isOpen={isOpen}>
        <ModalHeader>
          {edit ? `Edit ${edit.email}` : "Add new user"}
        </ModalHeader>
        <ModalBody>
          <div className={styles.row}>
            <span>Email</span>
            <CustomText
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.row}>
            <span>Password</span>
            <CustomText
              type="password"
              placeholder={edit ? "Blank = no change" : ""}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className={styles.row}>
            <span>First name</span>
            <CustomText
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className={styles.row}>
            <span>Last name</span>
            <CustomText
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          {/* <div className={styles.row}>
            <span>Role</span>
            <CustomReactSelect
              options={roles}
              value={role}
              onChange={(item) => setRole(item)}
            />
          </div> */}
          {error && <div className="error">{error}</div>}
        </ModalBody>
        {user && user.role === "user" ? (
          <>
            <ModalFooter>
              <IconButton
                disabled={loading}
                loading={loading}
                color="light-purple"
                onClick={handleAddClicked}
              >
                {edit ? "Edit" : "Add"}
              </IconButton>
              <IconButton
                disabled={loading}
                color="light-purple"
                onClick={() => onClose(false)}
              >
                Close
              </IconButton>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalFooter>
              <Row style={{ width: "40%", marginBottom: "10px" }}>
                <Col style={{ marginRight: "20px" }}>
                  <Button
                    style={{
                      width: "150%",
                      margin: "0 auto",
                      borderRadius: "30px",
                    }}
                    disabled={loading}
                    onClick={handleAddClicked}
                  >
                    {edit ? "Edit" : "Add"}
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{
                      width: "150%",
                      margin: "0 auto",
                      borderRadius: "30px",
                    }}
                    disabled={loading}
                    onClick={() => onClose(false)}
                  >
                    Close
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </>
        )}
      </Modal>
    </div>
  );
}

AddUserModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.object,
};

export default AddUserModal;
