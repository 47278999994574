import React, { useRef, useState } from 'react'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import PropTypes from "prop-types";
import { useAjax } from '@dreamvps/dream-utils/dist/hooks';
import { BasicTable } from '@dreamvps/dream-ui/dist';
import { toast } from 'react-toastify'
function MassOnboardingModal({ onClose, isOpen }) {
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState([])
    const fileRef = useRef()
    const ajax = useAjax();
    const handleImage = async (e) => {
        setLoading(true)
        const uploadFormData = new FormData();
        uploadFormData.append('file', e.target.files[0]);
        try {
            const data = await ajax("/agents/mass-onboard/1", uploadFormData);
            if (data.result === 'error') {
                throw new Error(data.message);
            }
            setLoading(false);
            const agents = data.agents.filter(agent => agent.result === "error")
            if (agents.length) {
                toast.error("Error while adding agents")
                setResponse([...agents])
            }
            else {
                toast.success("Agents added successfully")
                setResponse([])
                onClose(true)
            }
        } catch (err) {
            setLoading(false);
            toast.error(err.message)
        }
    }


    return (
        <div
            style={{
                marginTop: "50px",
                margin: "12.75rem auto !important",
            }}
        >
            <Modal isOpen={isOpen} size='xl' zIndex={5000} style={{ maxWidth: "700px" }}>
                <ModalHeader>
                    Add Agents
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='w-75 mx-auto' style={{ height: "170px", cursor: "pointer" }}>
                            <input type='file' hidden ref={fileRef} onChange={handleImage} />
                            <div
                                aria-hidden
                                onClick={() => {
                                    fileRef?.current?.click();
                                }}
                                className='border border-dark p-3 h-100 rounded d-flex flex-column  justify-content-around align-items-center cursor-pointer file-input'
                            >

                                <span>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='32'
                                        height='32'
                                        fill='currentColor'
                                        className='bi bi-plus-square'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                        <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                                    </svg>
                                </span>


                                <span className='h4 mt-2 font-weight-bold'>Upload</span>
                            </div>
                        </div>
                    </Row>
                    {
                        response.length ?
                            <BasicTable style={{ margin: "20px" }} className="mt-5">
                                <thead style={{ margin: "20px" }}>
                                    <tr>
                                        <th>Name</th>
                                        <th>Department</th>
                                        <th>Title</th>
                                        <th>Error Message</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        response.map((agent, index) => <tr key={index}>
                                            <td>{agent.first_name} {agent.last_name}</td>
                                            <td>{agent.department}</td>
                                            <td>{agent.title}</td>
                                            <td>{agent.message}</td>
                                        </tr>)
                                    }

                                </tbody>
                            </BasicTable>
                            : null
                    }
                </ModalBody>
                <ModalFooter>
                    <Row style={{ width: "40%", marginBottom: "10px" }}>
                        <Col>
                            <Button
                                style={{
                                    width: "100%",
                                    margin: "0 auto",
                                    borderRadius: "30px",
                                }}
                                disabled={loading}
                                onClick={() => {
                                    onClose(false)
                                    setResponse([])
                                }}
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </div>
    );
}
MassOnboardingModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

export default MassOnboardingModal