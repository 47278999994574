
import React from 'react'

const PageNotFound = () => {
    return (
        <div id="wrapper" style={{marginTop : "200px"}}>
            <img style={{width :"300px", height : "300px"}} src="https://i.imgur.com/qIufhof.png" />
            <div id="info">
                <h2 style={{marginRight : "30px"}}>403 : Access Denied</h2>
            </div>
        </div >
    )
}

export default PageNotFound