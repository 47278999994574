const SET_ALERT_MODAL_DATA = "SET_ALERT_MODAL_DATA";
const SET_CONFIRM_MODAL_DATA = "SET_CONFIRM_MODAL_DATA";
const SET_PROMPT_MODAL_DATA = "SET_PROMPT_MODAL_DATA";
const SET_CLIENT_STATE = "SET_CLIENT_STATE";
const SET_VIEWING_CHAT = "SET_VIEWING_CHAT";
const SET_QUOTED_MESSAGE = "SET_QUOTED_MESSAGE";
const SET_Loader_DATA = "SET_Loader_DATA";

const initialState = {
  alertModalData: { isOpen: false },
  confirmModalData: { isOpen: false },
  promptModalData: { isOpen: false },
  loader: { show: false },
  clientState: "",
  viewingChat: null,
  quotedMessage: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALERT_MODAL_DATA: {
      return { ...state, alertModalData: action.data };
    }
    case SET_Loader_DATA: {
      return { ...state, loader: action.data };
    }
    case SET_CONFIRM_MODAL_DATA: {
      return { ...state, confirmModalData: action.data };
    }
    case SET_PROMPT_MODAL_DATA: {
      return { ...state, promptModalData: action.data };
    }
    case SET_CLIENT_STATE: {
      return { ...state, clientState: action.state };
    }
    case SET_VIEWING_CHAT: {
      return { ...state, viewingChat: action.chat };
    }
    case SET_QUOTED_MESSAGE: {
      return { ...state, quotedMessage: action.quotedMessage };
    }
    default: {
      return state;
    }
  }
}

export const setAlertModalData = (data) => {
  return { type: SET_ALERT_MODAL_DATA, data };
};
export const setLoaderData = (data) => {
  return { type: SET_Loader_DATA, data };
};

export const setConfirmModalData = (data) => {
  return { type: SET_CONFIRM_MODAL_DATA, data };
};

export const setPromptModalData = (data) => {
  return { type: SET_PROMPT_MODAL_DATA, data };
};

export const setClientState = (state) => {
  return { type: SET_CLIENT_STATE, state };
};

export const setViewingChat = (chat) => {
  return { type: SET_VIEWING_CHAT, chat };
};

export const setQuotedMessage = (quotedMessage) => {
  return { type: SET_QUOTED_MESSAGE, quotedMessage };
};
