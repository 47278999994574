import styles from "./index.module.scss";

import React, { useState, useEffect, useCallback } from "react";
import qrcode from "qrcode-generator";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";
import { io } from "../../components/utils/socket";

export default function QrPage() {
  const ajax = useAjax();

  const [qrData, setQrData] = useState(null);
  const [agentID, setAgentID] = useState(null);

  const handleQrChanged = useCallback(
    (data) => {
      if (!agentID) {
        return;
      }

      if (data.agentID.toString() === agentID.toString()) {
        const qrObj = qrcode(0, "L");

        qrObj.addData(data.qr);
        qrObj.make();

        setQrData(qrObj.createDataURL(4));
      }
    },
    [agentID]
  );

  useEffect(() => {
    io.connect();

    io.on("qr-changed", handleQrChanged);

    return () => {
      io.off("qr-changed", handleQrChanged);
    };
  }, [handleQrChanged]);

  const getQrByToken = useCallback(
    async (token) => {
      const data = await ajax("/agents/getQrByToken", { token });

      if (data.result === "success") {
        const qrObj = qrcode(0, "L");

        qrObj.addData(data.qr);
        qrObj.make();

        setQrData(qrObj.createDataURL(4));
        setAgentID(data.agentID);
      } else {
        location.href = "/";
      }
    },
    [ajax]
  );

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const token = urlSearchParams.get("token");

    if (token) {
      getQrByToken(token);
    } else {
      location.href = "/";
    }
  }, [getQrByToken]);

  if (!qrData) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <img src={qrData} />
    </div>
  );
}
