import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";
import QrPage from "./qr";
import AdminLogin from "../components/modals/AdminLogin";
import NotAuthorized from "../components/403";
import { setUser } from "../store/user";
import {
    setAlertModalData,
    setClientState,
    setConfirmModalData,
    setPromptModalData,
} from "../store/settings";
import { AlertModal, ConfirmModal, PromptModal } from "@dreamvps/dream-ui/dist";
import { setChat, setChats, setMessages } from "../store/devices";
import Sidebar from "../components/Sidebar/index";
import UsersManagementModal from "../components/modals/users-management";
import Agents from "../components/agents";
import Groups from "../components/groups";
import Analytics from "../components/analytics";
import Policy from "../components/policy"
import CreateNewGroupModal from "../components/adminCreatGroup/index";
import { io } from "../components/utils/socket";
import { chatToBottom, isChatAtBottom } from "../components/utils";
import BotManagement from '../components/BotManagement';

export default function MainRouter() {
    const ajax = useAjax();
    const dispatch = useDispatch();

    const {
        confirmModalData,
        alertModalData,
        promptModalData,
        user,
        viewingChat,
    } = useSelector((state) => ({
        confirmModalData: state.settings.confirmModalData,
        alertModalData: state.settings.alertModalData,
        promptModalData: state.settings.promptModalData,
        user: state.user,
        viewingChat: state.settings.viewingChat,
    }));
    const viewingChatRef = useRef(viewingChat);
    viewingChatRef.current = viewingChat;

    const [dataInitialized, setDataInitialized] = useState(false);

    const fetchInitialData = useCallback(async () => {
        const data = await ajax("/user/initialData");
        setDataInitialized(true);

        if (data.user) {
            dispatch(setUser(data.user));
            io.connect();
            dispatch(setClientState("CONNECTED"));
        }
    }, [ajax, dispatch]);

    useEffect(() => {
        fetchInitialData();
    }, [fetchInitialData]);

    useEffect(() => {
        io.on("user-connected", () => {
            //
        });

        io.on("update-chats", (data) => {
            Object.keys(data.chats).forEach((deviceName) => {
                data.chats[deviceName].forEach((chat) => {
                    chat.device = deviceName;
                });

                dispatch(setChats(deviceName, data.chats[deviceName]));
            });
        });

        io.on("update-chat", (data) => {
            dispatch(setChat(data.deviceName, data.chat));
        });

        io.on("update-chat-messages", (data) => {
            let scrollChat = false;

            if (data.newMessage) {
                if (
                    viewingChatRef.current &&
                    data.chatID === viewingChatRef.current.id._serialized &&
                    isChatAtBottom()
                ) {
                    scrollChat = true;
                }

                new Notification(data.chatName, {
                    icon: "https://www.dreamvps.com/favicon.ico",
                    body: data.newMessage.body,
                });
            }

            dispatch(setMessages(data.deviceName, data.chatID, data.messages));

            if (scrollChat) {
                setTimeout(() => {
                    chatToBottom();
                }, 1);
            }
        });

        io.on("client-disconnected", () => {
            //
        });

        io.on("client-state-changed", (data) => {
            dispatch(setClientState(data.state));
        });

        return () => {
            io.off("user-connected");
            io.off("client-disconnected");
            io.off("client-state-changed");
        };
    }, [dispatch]);

    function handleAlertClosed(closedVia) {
        dispatch(
            setAlertModalData({
                button1: alertModalData.button1,
                button2: alertModalData.button2,
                isOpen: false,
            })
        );
        alertModalData.resolve(closedVia);
    }

    function handleConfirmClosed(closedVia) {
        dispatch(
            setConfirmModalData({
                button1: confirmModalData.button1,
                button2: confirmModalData.button2,
                isOpen: false,
            })
        );
        confirmModalData.resolve(closedVia);
    }

    function handlePromptClosed(closedVia) {
        dispatch(
            setPromptModalData({
                button1: promptModalData.button1,
                button2: promptModalData.button2,
                isOpen: false,
            })
        );
        promptModalData.resolve(closedVia);
    }

    function renderLayout() {
        if (window.location.pathname === "/qr") {
            return null;
        }

        if (user && user.role === "admin") {
            return <Sidebar />;
        }

        if (
            (window.location.pathname === "/admin" && user && user.role === "user") ||
            window.location.pathname === "/admin/createnewgroup"
        ) {
            return <NotAuthorized />;
        }
    }

    function renderSwitch() {
        if (!user.id) {
            return (
                <Switch>
                    <Route path="/" component={AdminLogin} />
                </Switch>
            );
        }

        return (
            <Switch>
                <Route
                    exact
                    path={["/", "/admin", "/admin/usermanagement"]}
                    component={UsersManagementModal}
                />

                <Route exact path="/admin/agents" component={Agents} />

                <Route exact path="/admin/groups" component={Groups} />
                <Route exact path="/admin/policy" component={Policy} />
                <Route exact path="/admin/analytics" component={Analytics} />
                <Route exact path="/admin/bot-management" component={BotManagement} />

                <Route
                    exact
                    path="/admin/createnewgroup"
                    component={CreateNewGroupModal}
                />

                <Route exact path="/qr" component={QrPage}></Route>

                <Route exact path="/Notauthorized123" component={NotAuthorized}></Route>
            </Switch>
        );
    }

    if (!dataInitialized) {
        return null;
    }

    return (
        <>
            {renderLayout()}

            {renderSwitch()}

            {alertModalData && (
                <AlertModal
                    isOpen={alertModalData.isOpen}
                    onClose={handleAlertClosed}
                    title={alertModalData.title}
                    message={alertModalData.message}
                    button={alertModalData.button}
                    notification={alertModalData.notification}
                />
            )}

            {confirmModalData && (
                <ConfirmModal
                    isOpen={confirmModalData.isOpen}
                    onClose={handleConfirmClosed}
                    title={confirmModalData.title}
                    message={confirmModalData.message}
                    button1={confirmModalData.button1}
                    button2={confirmModalData.button2}
                    beforeClose={confirmModalData.beforeClose}
                />
            )}

            {promptModalData && (
                <PromptModal
                    isOpen={promptModalData.isOpen}
                    onClose={handlePromptClosed}
                    title={promptModalData.title}
                    message={promptModalData.message}
                    defaultText={promptModalData.defaultText}
                    button1={promptModalData.button1}
                    button2={promptModalData.button2}
                    acceptOnlyValue={promptModalData.acceptOnlyValue}
                    textType={promptModalData.textType}
                    beforeClose={promptModalData.beforeClose}
                    placeholder={promptModalData.placeholder}
                    withCheckbox={promptModalData.withCheckbox}
                />
            )}
        </>
    );
}
