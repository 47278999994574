import styles from './index.module.scss';

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Card, Button } from 'reactstrap';
import {
  useAjax,
  useAlert,
  useConfirm,
} from '@dreamvps/dream-utils/dist/hooks';
import { BasicTable, Spinner } from '@dreamvps/dream-ui/dist';
import copy from 'copy-to-clipboard';
import { io } from '../utils/socket';
import { debounce } from '../utils/debounce';
import { setViewingChat } from '../../store/settings';
import AddAgentModal from '../modals/add-agent';
// import AddBotModal from "../modals/add-bot";
import MassOnboardingModal from '../modals/mass-onboarding';
import { CustomText } from '@dreamvps/dream-ui/dist';
import CustomPagination from '../pagination';

function Agents() {
  const ajax = useAjax();
  const confirm = useConfirm();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isAddAgentModalOpen, setIsAddAgentModalOpen] = useState(false);
  // const [isAddBotModalOpen, setIsAddBotModalOpen] = useState(false);
  const [isMassOnboardingModalOpen, setMassOnboardingModalOpen] =
    useState(false);

  const [agentsQuery, setAgentsQuery] = useState({
    page: 1,
    limit: 10,
    search: '',
  });
  const [totalAgents, setTotalAgents] = useState(0);

  const [generating, setGenerating] = useState(false);

  const setFilter = (value) => {
    setAgentsQuery((prev) => ({ ...prev, page: 1, search: value }));
  };
  const debouncedSetSearch = debounce(setFilter);

  const listAgents = useCallback(async () => {
    const data = await ajax(
      `/agents/list/1?search=${agentsQuery.search}&page=${agentsQuery.page}&limit=${agentsQuery.limit}`
    );
    setAgents(data.agents);
    setTotalAgents(data.total);
  }, [ajax, agentsQuery]);

  useEffect(() => {
    listAgents();
  }, [listAgents]);

  useEffect(() => {
    function handleAgentAuthenticated() {
      listAgents();
    }

    function handleAgentDisconnected(data) {
      const agent = agents.find((item) => item.id === data.agentID);

      if (agent) {
        agent.state = null;
      }

      setAgents([...agents]);
    }

    io.on('authenticated', handleAgentAuthenticated);
    io.on('disconnected', handleAgentDisconnected);

    return () => {
      io.off('authenticated', handleAgentAuthenticated);
      io.off('disconnected', handleAgentDisconnected);
    };
  }, [dispatch, listAgents, agents]);

  async function handleGenerateClicked(agent) {
    setGenerating(true);

    const data = await ajax(`/agents/genQrOtp/${agent.id}`);

    if (data.result === 'success') {
      copy(data.qrLink);

      await alert({
        title: 'Success',
        message: data.message,
      });
    } else {
      await alert({
        title: 'Error',
        message: data.message,
      });
    }

    setGenerating(false);
  }

  function handleEditAgentClicked(agent) {
    setSelectedAgent(agent);
    setIsAddAgentModalOpen(true);
  }

  function handleDisconnectClicked(agent) {
    confirm({
      title: `Disconnect`,
      message: `Are you sure you want to disconnect from ${agent.first_name}?`,
      beforeClose: async (state) => {
        if (state !== 'button2') {
          return;
        }

        dispatch(setViewingChat(null));
        await ajax('/agents/logout', { agentID: agent.id });
      },
    });
  }

  function handleAddNewAgentClicked() {
    setIsAddAgentModalOpen(true);
  }
  // function handleAddNewBotClicked() {
  //     setIsAddBotModalOpen(true);
  // }
  function handleMassOnboardingClicked() {
    setMassOnboardingModalOpen(true);
  }

  async function handleAddAgentModalClosed(state) {
    if (state) {
      await listAgents();
    }

    setIsAddAgentModalOpen(false);
    setSelectedAgent(null);
  }

  // async function handleAddBotModalClosed(state) {
  //     if (state) {
  //         await listAgents();
  //     }

  //     setIsAddBotModalOpen(false);
  // }

  async function handleMassOnboardingModalClosed(state) {
    if (state) {
      await listAgents();
    }

    setMassOnboardingModalOpen(false);
  }
  function handleRemoveAgentClicked(agent) {
    confirm({
      title: 'Remove agent',
      message: `Are you sure you want to remove ${agent.first_name}?`,
      beforeClose: async (state) => {
        if (state !== 'button2') {
          return;
        }

        await ajax('/agents/remove', { agentID: agent.id });
        await listAgents();
      },
    });
  }

  const agentPrevPage = () => {
    setAgentsQuery((prev) => ({ ...prev, page: prev.page - 1 }));
  };
  const agentNextPage = () => {
    setAgentsQuery((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  return (
    <div style={{ width: '80%', marginTop: '100px', marginRight: '100px' }}>
      <Row size='xl'>
        <div className='px-0'>
          <Row className='mb-3'>
            <Col className='d-flex align-items-center'>
              <div style={{ fontWeight: 'bold' }} className='me-2'>
                Agents
              </div>

              <CustomText
                placeholder='Search agents...'
                onChange={(e) => debouncedSetSearch(e.target.value)}
              />
            </Col>
            <Col className='d-flex justify-content-end'>
              <Button
                className='adminButton me-2'
                onClick={handleAddNewAgentClicked}
              >
                Add new agent
              </Button>
              {/* <Button className="adminButton me-2" onClick={handleAddNewBotClicked}>
                                Create bot
                            </Button> */}
              <Button
                className='adminButton'
                onClick={handleMassOnboardingClicked}
              >
                Mass agent onboarding
              </Button>
            </Col>
          </Row>
        </div>

        <Card
          body
          outline
          color='secondary'
          style={{ height: '500px', overflowX: 'auto' }}
        >
          <BasicTable style={{ margin: '20px' }}>
            <thead style={{ margin: '20px' }}>
              <tr>
                <th style={{ marginBottom: '20px', fontWeight: 'bold' }}>ID</th>
                <th style={{ marginBottom: '20px', fontWeight: 'bold' }}>
                  Name
                </th>
                <th style={{ marginBottom: '20px', fontWeight: 'bold' }}>
                  Department
                </th>
                <th style={{ marginBottom: '20px', fontWeight: 'bold' }}>
                  Title
                </th>
                <th style={{ marginBottom: '20px', fontWeight: 'bold' }}>
                  Email
                </th>
                <th style={{ marginBottom: '20px', fontWeight: 'bold' }}>
                  Phone Number
                </th>
                <th style={{ marginBottom: '20px', fontWeight: 'bold' }}>
                  Status
                </th>

                <th style={{ marginBottom: '20px', fontWeight: 'bold' }}></th>
              </tr>
            </thead>
            <tbody>
              {!agents && (
                <tr>
                  <td colSpan={7}>
                    <Spinner />
                  </td>
                </tr>
              )}

              {agents?.length === 0 && (
                <tr>
                  <td colSpan={7}>No agents</td>
                </tr>
              )}

              {agents?.map((agent, key) => (
                <tr key={key}>
                  <td>#{agent.id}</td>
                  <td>
                    {agent.first_name} {agent.last_name}
                    {/* {agent.is_bot ? <strong>(Bot)</strong> : null} */}
                  </td>
                  <td>{agent.department}</td>
                  <td>{agent.title}</td>
                  <td>{agent.email}</td>
                  <td>{agent.phone_number}</td>
                  <td>{agent.state || 'DISCONNECTED'}</td>
                  <td>
                    <div className={styles.buttons}>
                      <Button
                        className='adminButton'
                        onClick={() => handleEditAgentClicked(agent)}
                      >
                        Edit agent
                      </Button>
                      {agent.state === 'CONNECTED' && (
                        <Button
                          className='adminButton'
                          onClick={() => handleDisconnectClicked(agent)}
                        >
                          Disconnect
                        </Button>
                      )}
                      {agent.state !== 'CONNECTED' && (
                        <Button
                          className='adminButton'
                          onClick={() => handleGenerateClicked(agent)}
                          disabled={generating}
                        >
                          Generate QR link
                        </Button>
                      )}
                      <Button
                        className='adminButton'
                        onClick={() => handleRemoveAgentClicked(agent)}
                      >
                        Remove agent
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        </Card>
        <CustomPagination
          page={agentsQuery.page}
          totalPages={Math.ceil(totalAgents / agentsQuery.limit)}
          onPrev={agentPrevPage}
          onNext={agentNextPage}
          disableNext={
            (agentsQuery.page - 1) * agentsQuery.limit + agents.length ===
            totalAgents
          }
        />
      </Row>

      <AddAgentModal
        isOpen={isAddAgentModalOpen}
        onClose={handleAddAgentModalClosed}
        agent={selectedAgent}
      />
      {/* <AddBotModal
                isOpen={isAddBotModalOpen}
                onClose={handleAddBotModalClosed}
            /> */}

      <MassOnboardingModal
        isOpen={isMassOnboardingModalOpen}
        onClose={handleMassOnboardingModalClosed}
      />
    </div>
  );
}

export default Agents;
