import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  BasicTable,
  Checkbox,
  IconButton,
  Spinner,
} from "@dreamvps/dream-ui/dist";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";

function AssignUsersToGroupModal({ isOpen, onClose, group }) {
  const ajax = useAjax();

  const [users, setUsers] = useState(null);

  const [loading, setLoading] = useState(false);

  const getAllUsers = useCallback(async () => {
    const data = await ajax("/user/getAll", {
      withAssignedGroups: true,
      groupID: group.id,
      roleFilter: "user",
    });

    data.assignedUsersToGroups.forEach((item) => {
      const user = data.users.find((usr) => usr.id === item.user_id);

      user.assigned = true;
    });

    setUsers(data.users);
  }, [ajax, group]);

  useEffect(() => {
    if (isOpen) {
      setUsers(null);

      setLoading(false);

      getAllUsers();
    }
  }, [getAllUsers, isOpen]);

  function handleAssignedClicked(e, user) {
    e.preventDefault();

    user.assigned = !user.assigned;
    setUsers([...users]);
  }

  async function handleSaveClicked() {
    setLoading(true);

    const usersIds = users
      .filter((user) => user.assigned)
      .map((user) => user.id);

    await ajax("/groups/assignUsers", { groupID: group.id, usersIds });

    onClose(true);
  }

  if (!group) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} toggle={() => onClose(false)}>
      <ModalHeader toggle={() => onClose(false)}>
        Assign users to {group.name}
      </ModalHeader>
      <ModalBody>
        <BasicTable>
          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!users && (
              <tr>
                <td>
                  <Spinner />
                </td>
              </tr>
            )}

            {users?.length === 0 && (
              <tr>
                <td>No users</td>
              </tr>
            )}

            {users?.map((user, key) => (
              <tr
                key={key}
                onClick={(e) => handleAssignedClicked(e, user)}
                className="pointer"
              >
                <td>
                  <Checkbox checked={user.assigned || false} readOnly />
                </td>
                <td>{user.email}</td>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
              </tr>
            ))}
          </tbody>
        </BasicTable>
      </ModalBody>
      <ModalFooter>
        <IconButton
          color="adminButton"
          onClick={handleSaveClicked}
          loading={loading}
          disabled={loading}
        >
          Save
        </IconButton>
        <IconButton color="text" onClick={() => onClose(false)}>
          Close
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AssignUsersToGroupModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  group: PropTypes.object,
};

export default AssignUsersToGroupModal;
