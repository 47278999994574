/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Button, Card, CardBody, CardTitle, Collapse, Row } from "reactstrap";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";
import { CustomText } from "@dreamvps/dream-ui/dist";
import { toast } from "react-toastify";

function BotManagement() {
  const [open, setOpen] = useState(true);
  const [commands, setCommands] = useState({
    startMonitoring: "",
    stopMonitoring: "",
    monitoringStatus: "",
  });
  const [message, setMessages] = useState({
    monitoringEnabled: "",
    monitoringDisabled: "",
    monitoringStarted: "",
    monitoringStopped: "",
  });
  const ajax = useAjax();

  const getPolicies = useCallback(async () => {
    const policies = await ajax(`/agent-groups/1/policies`);
    const commands = policies.policies[0].bot.commands;
    const messages = policies.policies[0].bot.message;
    setMonitorStart(commands.startMonitoring);
    setMonitorEnd(commands.stopMonitoring);
    setMonitorStatus(commands.monitoringStatus);
    setBotMessage("monitoringEnabled", messages.monitoringEnabled);
    setBotMessage("monitoringDisabled", messages.monitoringDisabled);
    setBotMessage("monitoringStarted", messages.monitoringStarted);
    setBotMessage("monitoringStopped", messages.monitoringStopped);
  }, [ajax]);

  const setMonitorStart = (data) => {
    setCommands((prev) => ({ ...prev, startMonitoring: data }));
  };
  const setMonitorEnd = (data) => {
    setCommands((prev) => ({ ...prev, stopMonitoring: data }));
  };
  const setMonitorStatus = (data) => {
    setCommands((prev) => ({ ...prev, monitoringStatus: data }));
  };
  const setBotMessage = (key, data) => {
    setMessages((prev) => ({ ...prev, [key]: data }));
  };
  useEffect(() => {
    getPolicies();
  }, [getPolicies]);

  const onSubmit = async () => {
    try {
      await ajax("/agent-groups/1/policies/edit", {
        bot: {
          commands: { ...commands },
          message: { ...message },
        },
      });
      toast.success("Policies updated successfully");
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div
      style={{
        overflowY: "auto",
        height: "100vh",
        paddingTop: "100px",
        width: "80%",
      }}
    >
      <Row className="mb-3" />
      <h1 className="text-center">Chatbot Management</h1>

      <Collapse isOpen={open} className="mb-3">
        <Card>
          <CardBody>
            <CardTitle tag="h5" className="mb-3">
              Bot commands
            </CardTitle>
            <div>
              <p className="mb-3">Monitoring start phrase:</p>
              <CustomText
                onChange={(e) => setMonitorStart(e.target.value)}
                value={commands.startMonitoring}
              />
            </div>
            <div className="mt-4">
              <p className="mb-3">Monitoring stop phrase:</p>
              <CustomText
                onChange={(e) => setMonitorEnd(e.target.value)}
                value={commands.stopMonitoring}
              />
            </div>
            <div className="mt-4">
              <p className="mb-3">Monitoring status phrase:</p>
              <CustomText
                onChange={(e) => setMonitorStatus(e.target.value)}
                value={commands.monitoringStatus}
              />
            </div>
          </CardBody>
          <CardBody>
            <CardTitle tag="h5" className="mb-3">
              Bot messages
            </CardTitle>
            <div>
              <p className="mb-3">Monitoring start message:</p>
              <CustomText
                onChange={(e) =>
                  setBotMessage("monitoringStarted", e.target.value)
                }
                value={message.monitoringStarted}
              />
            </div>
            <div className="mt-4">
              <p className="mb-3">Monitoring stop message:</p>
              <CustomText
                onChange={(e) =>
                  setBotMessage("monitoringStopped", e.target.value)
                }
                value={message.monitoringStopped}
              />
            </div>
            <div className="mt-4">
              <p className="mb-3">Monitoring enabled message:</p>
              <CustomText
                onChange={(e) =>
                  setBotMessage("monitoringEnabled", e.target.value)
                }
                value={message.monitoringEnabled}
              />
            </div>
            <div className="mt-4">
              <p className="mb-3">Monitoring disabled message:</p>
              <CustomText
                onChange={(e) =>
                  setBotMessage("monitoringDisabled", e.target.value)
                }
                value={message.monitoringDisabled}
              />
            </div>
          </CardBody>
          <Button className="m-3" onClick={onSubmit}>
            Save
          </Button>
        </Card>
      </Collapse>
    </div>
  );
}

export default BotManagement;
