import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IconButton, Spinner, CustomText } from "@dreamvps/dream-ui/dist";
import qrcode from "qrcode-generator";
import { io } from "../../utils/socket";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";
import { getDirection } from "@dreamvps/dream-utils/dist";

function SetWelcomeMessageModal({ isOpen, onClose, group }) {
  const ajax = useAjax();

  const [welcomeMessage, setWelcomeMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const getWelcomeMessage = useCallback(async () => {
    if (!group) {
      return;
    }

    const data = await ajax("/groups/getWelcomeMessage", { groupID: group.id });

    setWelcomeMessage(data.welcomeMessage);
  }, [group, ajax]);

  useEffect(() => {
    if (isOpen) {
      getWelcomeMessage();

      setLoading(false);
    }
  }, [getWelcomeMessage, isOpen]);

  async function handleSaveClicked() {
    setLoading(true);

    await ajax("/groups/setWelcomeMessage", {
      groupID: group.id,
      welcomeMessage,
    });

    onClose(true);
  }

  if (!group) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} toggle={() => onClose(false)}>
      <ModalHeader toggle={() => onClose(false)}>
        Set welcome message for {group.name}
      </ModalHeader>
      <ModalBody>
        <div>
          You can leave the field blank to disable welcome message for this
          group
        </div>
        <div>
          <CustomText
            type="textarea"
            style={getDirection(welcomeMessage)}
            value={welcomeMessage}
            onChange={(e) => setWelcomeMessage(e.target.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          loading={loading}
          color="adminButton"
          onClick={handleSaveClicked}
        >
          Update
        </IconButton>
        <IconButton
          disabled={loading}
          color="adminButton"
          onClick={() => onClose(false)}
        >
          Cancel
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

SetWelcomeMessageModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  group: PropTypes.object,
};

export default SetWelcomeMessageModal;
