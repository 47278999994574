import { BasicTable, CustomText } from "@dreamvps/dream-ui/dist";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";
import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "reactstrap";
import CustomPagination from "../pagination";
import { debounce } from "../utils/debounce";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

function Analytics() {
  const ajax = useAjax();

  const [analyticsQuery, setAnalyticsQuery] = useState({
    page: 1,
    limit: 25,
    search: "",
    order: "created_at:desc",
  });
  const [totalResults, setTotalResults] = useState(0);
  const [results, setResults] = useState([]);

  const listMessages = useCallback(async () => {
    const data = await ajax(
      `/analytics/list?search=${analyticsQuery.search}&page=${analyticsQuery.page}&limit=${analyticsQuery.limit}&order=${analyticsQuery.order}`
    );
    setResults(data.messages);
    setTotalResults(data.total);
  }, [ajax, analyticsQuery]);

  useEffect(() => {
    listMessages();
  }, [listMessages]);

  const resPrevPage = () => {
    setAnalyticsQuery((prev) => ({ ...prev, page: prev.page - 1 }));
  };
  const resNextPage = () => {
    setAnalyticsQuery((prev) => ({ ...prev, page: prev.page + 1 }));
  };
  const setFilter = (value) => {
    setAnalyticsQuery((prev) => ({ ...prev, page: 1, search: value }));
  };
  const setOrderBy = (order) => {
    setAnalyticsQuery((prev) => ({ ...prev, page: 1, order }));
  };
  const debouncedSetSearch = debounce(setFilter);

  const orderButtons = (column) => {
    const currentOrderColumn = analyticsQuery?.order?.split(":")[0];
    const upClass =
      currentOrderColumn !== column || analyticsQuery.order === `${column}:desc`
        ? "opactiy-100"
        : "opactiy-0";
    const downClass =
      currentOrderColumn !== column || analyticsQuery.order === `${column}:asc`
        ? "opactiy-100"
        : "opactiy-0";
    return (
      <span className="d-flex flex-column">
        <button
          className={`adminButton transparent ${upClass}`}
          onClick={() =>
            analyticsQuery.order !== `${column}:asc` &&
            setOrderBy(`${column}:asc`)
          }
        >
          <ArrowDropUp />
        </button>
        <button
          className={`adminButton transparent ${downClass}`}
          onClick={() =>
            analyticsQuery.order !== `${column}:desc` &&
            setOrderBy(`${column}:desc`)
          }
        >
          <ArrowDropDown />
        </button>
      </span>
    );
  };

  return (
    <div
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        height: "100vh",
        paddingTop: "100px",
        width: "80%",
      }}
    >
      <div className="px-0">
        <Row className="mb-3">
          <Col className="d-flex align-items-center">
            <div
              style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
              className="me-2"
            >
              Analytics Page
            </div>

            <CustomText
              placeholder="Search messages..."
              onChange={(e) => debouncedSetSearch(e.target.value)}
            />
          </Col>
        </Row>
      </div>
      <Card
        body
        outline
        color="secondary"
        className="overflow-auto"
        style={{ height: "calc(100vh - 250px)", overflowX: "auto" }}
      >
        <div>
          <BasicTable style={{ margin: "20px" }}>
            <thead style={{ margin: "20px" }}>
              <tr>
                <th
                  style={{
                    marginBottom: "20px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span className="d-inline-block">ID</span>
                    <span className="d-inline-block">{orderButtons("id")}</span>
                  </div>
                </th>
                <th
                  style={{
                    marginBottom: "20px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span>Agent ID</span>
                    <span className="d-inline-block">
                      {orderButtons("agent_id")}
                    </span>
                  </div>
                </th>
                <th
                  style={{
                    marginBottom: "20px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span>Message ID</span>
                    <span className="d-inline-block">
                      {orderButtons("message_id")}
                    </span>
                  </div>
                </th>
                <th
                  style={{
                    marginBottom: "20px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span>Content</span>
                    <span className="d-inline-block">
                      {orderButtons("content")}
                    </span>
                  </div>
                </th>
                <th
                  style={{
                    marginBottom: "20px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span>Action</span>
                    <span className="d-inline-block">
                      {orderButtons("action")}
                    </span>
                  </div>
                </th>
                <th
                  style={{
                    marginBottom: "20px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span>Message From</span>
                    <span className="d-inline-block">
                      {orderButtons("message_from")}
                    </span>
                  </div>
                </th>
                <th
                  style={{
                    marginBottom: "20px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span>Message To</span>
                    <span className="d-inline-block">
                      {orderButtons("message_to")}
                    </span>
                  </div>
                </th>
                <th
                  style={{
                    marginBottom: "20px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span>Created At</span>
                    <span className="d-inline-block">
                      {orderButtons("created_at")}
                    </span>
                  </div>
                </th>
                <th
                  style={{
                    marginBottom: "20px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span>Group Name</span>
                    <span className="d-inline-block">
                      {orderButtons("group_name")}
                    </span>
                  </div>
                </th>
                <th
                  style={{
                    marginBottom: "20px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span>Group ID</span>
                    <span className="d-inline-block">
                      {orderButtons("wh_group_id")}
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {!results && (
                <tr>
                  <td colSpan={7}>
                    <Spinner />
                  </td>
                </tr>
              )}

              {results?.length === 0 && (
                <tr>
                  <td colSpan={10}>No message(s)!</td>
                </tr>
              )}

              {results?.map((agent, key) => (
                <tr key={key}>
                  <td>
                    <span style={{ marginRight: "10px" }}>#{agent.id}</span>
                  </td>
                  <td>
                    <span style={{ marginRight: "10px" }}>
                      {agent.agent_id}
                    </span>
                  </td>
                  <td>
                    <span style={{ marginRight: "10px" }}>
                      {agent.message_id}
                    </span>
                  </td>
                  <td>
                    <span style={{ marginRight: "10px" }}>{agent.content}</span>
                  </td>
                  <td>
                    <span style={{ marginRight: "10px" }}>{agent.action}</span>
                  </td>
                  <td>
                    <span style={{ marginRight: "10px" }}>
                      {agent.message_from}
                    </span>
                  </td>
                  <td>
                    <span style={{ marginRight: "10px" }}>
                      {agent.message_to}
                    </span>
                  </td>
                  <td>
                    <span style={{ marginRight: "10px" }}>
                      {agent.created_at}
                    </span>
                  </td>
                  <td>
                    <span style={{ marginRight: "10px" }}>
                      {agent.group_name}
                    </span>
                  </td>
                  <td>
                    <span style={{ marginRight: "10px" }}>
                      {agent.wh_group_id}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        </div>
      </Card>
      <CustomPagination
        page={analyticsQuery.page}
        totalPages={Math.ceil(totalResults / analyticsQuery.limit)}
        onPrev={resPrevPage}
        onNext={resNextPage}
        disableNext={
          (analyticsQuery.page - 1) * analyticsQuery.limit + results.length ===
          totalResults
        }
      />
    </div>
  );
}

export default Analytics;
