import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CustomText, IconButton } from "@dreamvps/dream-ui/dist";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";
import { getDirection } from "@dreamvps/dream-utils/dist/index";
import { ReactComponent as PlusSvg } from "../../svgs/plus.svg";
import { ReactComponent as CloseSvg } from "../../svgs/close.svg";

function SetContentToJoinModal({ isOpen, onClose, group }) {
  const ajax = useAjax();

  const [contents, setContents] = useState([]);

  const getContentToJoin = useCallback(async () => {
    if (group) {
      const data = await ajax("/groups/getContentToJoin", {
        groupID: group.id,
      });

      setContents(data.contents);
    } else {
      const data = await ajax("/groups/getContentToJoin");

      const totalGroups = {};
      const matches = {};

      data.contents.forEach((content) => {
        totalGroups[content.group_id] = true;
        matches[content.text] = matches[content.text] || 0;
        matches[content.text]++;
      });

      if (Object.keys(totalGroups).length === Object.keys(matches).length) {
        setContents(Object.keys(matches).map((k) => ({ text: k })));
      } else {
        setContents([]);
      }
    }
  }, [ajax, group]);

  useEffect(() => {
    if (isOpen) {
      getContentToJoin();
    }
  }, [isOpen, getContentToJoin]);

  function handleContentChanged(e, key) {
    contents[key].text = e.target.value;
    setContents([...contents]);
  }

  function handleAddClicked() {
    contents.push({ text: "" });
    setContents([...contents]);
  }

  function handleRemoveContent(key) {
    contents.splice(key, 1);
    setContents([...contents]);
  }

  async function handleUpdateClicked() {
    const _contents = [];

    contents.forEach((content) => {
      content.text = content.text.trim();

      if (content.text) {
        _contents.push(content);
      }
    });

    const ajaxData = {
      contents: _contents,
    };

    if (group) {
      ajaxData.groupID = group.id;
    }

    await ajax("/groups/setContentToJoin", ajaxData);

    onClose(true);
  }

  function renderHeader() {
    if (group) {
      return `Set content to join for ${group.name}`;
    }

    return (
      <>
        Set content to join for <b>All</b> groups
      </>
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={() => onClose(false)}>
      <ModalHeader toggle={() => onClose(false)}>{renderHeader()}</ModalHeader>
      <ModalBody>
        <div className={styles.tip}>
          Tip: use <b>$</b> as a placeholder for the group name
        </div>

        {contents?.map((content, key) => (
          <div key={key} className={styles.textRow}>
            <span>#{key + 1}</span>
            <CustomText
              style={getDirection(content.text)}
              value={content.text}
              onChange={(e) => handleContentChanged(e, key)}
            />
            <CloseSvg onClick={() => handleRemoveContent(key)} />
          </div>
        ))}

        <div className={styles.addWrp}>
          <PlusSvg onClick={handleAddClicked} />
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton color="adminButton" onClick={handleUpdateClicked}>
          Update
        </IconButton>
        <IconButton color="text" onClick={() => onClose(false)}>
          Cancel
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

SetContentToJoinModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  group: PropTypes.object,
};

export default SetContentToJoinModal;
