const SET_SELECTED_MESSAGES = "SET_SELECTED_MESSAGES";

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_MESSAGES: {
      return action.selectedMessages;
    }
    default: {
      return state;
    }
  }
}

export const setSelectedMessages = (selectedMessages) => {
  return { type: SET_SELECTED_MESSAGES, selectedMessages };
};
