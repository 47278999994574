import React, { useRef, useState } from 'react'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import PropTypes from "prop-types";

function AddCSVLink({ onClose, isOpen, setCSVLink }) {
    const csvLinkRef = useRef()
    const [error, setError] = useState(false)
    const handleSubmit = () => {
        if (csvLinkRef.current?.value) {
            setError(false)
            setCSVLink(csvLinkRef.current?.value)
            onClose()
        } else {
            setError(true)
        }
    }

    return (
        <div
            style={{
                marginTop: "50px",
                margin: "12.75rem auto !important",
            }}
        >
            <Modal isOpen={isOpen} size='xl' zIndex={5000} style={{ maxWidth: "700px" }}>
                <ModalHeader>
                    Add Agents
                </ModalHeader>
                <ModalBody>
                    <input style={{ height: '61px', border: '1px solid', borderRadius: '6px', padding: '21px 24px', width: '100%' }} placeholder="Enter CSV url" ref={csvLinkRef} />
                    {error ? <p className='text-danger'>CSV Link is required</p> : null}
                </ModalBody>
                <ModalFooter>
                    <Row style={{ width: "40%", marginBottom: "10px" }}>
                        <Col>
                            <Button
                                style={{
                                    width: "100%",
                                    margin: "0 auto",
                                    borderRadius: "30px",
                                }}
                                onClick={() => {
                                    onClose();
                                    setError(false)
                                }}
                            >
                                Close
                            </Button>
                            <Button
                                style={{
                                    width: "100%",
                                    margin: "0 auto",
                                    borderRadius: "30px",
                                }}
                                onClick={handleSubmit}
                                className="mt-3"
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </div>
    );
}
AddCSVLink.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    setCSVLink: PropTypes.func
};

export default AddCSVLink