import styles from "./index.module.scss";

import React, { useEffect, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Button } from "reactstrap";
import { CustomText, Spinner } from "@dreamvps/dream-ui/dist";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";
import { ReactComponent as CheckSvg } from "../svgs/check.svg";
import { getPictureOfChat } from "../utils";

function CreateNewGroupModal({ isOpen, onClose }) {
  const ajax = useAjax();
  const [whitelabel, setWhitelabel] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [participants, setParticipants] = useState({});
  const [filter, setFilter] = useState("");
  const [contacts, setContacts] = useState([]);

  const [error, setError] = useState(null);
  const [fetchingContacts, setFetchingContacts] = useState(false);
  const [loading, setLoading] = useState(false);
  const updateContacts = useCallback(async () => {
    if (!whitelabel) {
      return;
    }

    setGroupName("");
    setParticipants({});
    setFilter("");
    setContacts([]);
    setFetchingContacts(true);
    const data = await ajax("/chat/updateContacts", {
      whitelabel: whitelabel.value,
    });

    setFetchingContacts(false);

    setContacts(data.contacts);
  }, [ajax, whitelabel]);

  useEffect(() => {
    updateContacts();
  }, [isOpen, updateContacts, whitelabel]);

  useEffect(() => {
    if (isOpen) {
      setWhitelabel(null);
      setGroupName("");
      setParticipants({});
      setFilter("");
      setContacts([]);

      setError(null);
      setLoading(false);
    }
  }, [isOpen]);

  const filteredContacts = useMemo(() => {
    const arr = contacts.filter(
      (contact) =>
        contact.isMyContact &&
        (!filter ||
          (contact.name || contact.pushname || contact.verifiedName)
            .toLowerCase()
            .includes(filter.toLowerCase()))
    );

    for (let i = 0; i < arr.length; i++) {
      arr[i] = { ...arr[i] };

      arr[i].formattedNumber = `+${arr[i].number}`;

      if (arr[i].formattedNumber.startsWith("+972")) {
        arr[i].formattedNumber = arr[i].formattedNumber.replace("+972", "0");
      }
    }

    return arr;
  }, [contacts, filter]);

  function handleContactToggle(contact) {
    if (participants[contact.id._serialized]) {
      delete participants[contact.id._serialized];
    } else {
      participants[contact.id._serialized] = true;
    }

    setParticipants({ ...participants });
  }

  async function handleCreateClicked() {
    const _groupName = groupName.trim();

    setError(null);

    if (!_groupName) {
      return setError("Please choose a name for your group");
    }

    setLoading(true);

    await ajax("/chat/createNewGroup", {
      whitelabel: whitelabel.value,
      groupName,
      participants: Object.keys(participants).filter(
        (key) => participants[key]
      ),
    });

    setLoading(false);

    onClose(true);
  }

  const totalParticipants = useMemo(
    () => Object.keys(participants).length,
    [participants]
  );

  function renderContact(contact, key) {
    return (
      <div
        key={key}
        className={styles.contactWrapper}
        onClick={() => handleContactToggle(contact)}
      >
        <div className={styles.picture}>
          {getPictureOfChat({ whitelabel: null }, styles.image)}
          {participants[contact.id._serialized] && (
            <CheckSvg className={styles.check} />
          )}
        </div>
        <div>{contact.name || contact.pushname || contact.verifiedName}</div>
      </div>
    );
  }

  function renderCreateContent() {
    if (totalParticipants === 0) {
      return "Create";
    }

    return (
      <>
        <div>Create group</div>
        <div>
          ({totalParticipants} participant{totalParticipants > 1 ? "s" : ""})
        </div>
      </>
    );
  }

  function renderFields() {
    if (fetchingContacts) {
      return (
        <div>
          <Spinner />
        </div>
      );
    }

    return (
      <>
        <div className={styles.row}>
          <span>Group name:</span>
          <CustomText
            type="text"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>
        <div className={styles.list}>
          <div className={styles.filter}>
            <CustomText
              placeholder="Search..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>

          {filteredContacts.map((contact, key) => renderContact(contact, key))}
        </div>

        {error && <div className="error">{error}</div>}
      </>
    );
  }

  return (
    <div
      style={{
        justifyContent: "right",
        width: "20%",
        marginTop: "100px",
        marginRight: "600px",
      }}
    >
      <Row size="md">
        <Col style={{ marginBottom: "20px", fontWeight: "bold" }}>
          Create new group [WORK IN PROGRESS]
        </Col>
        <Card body outline color="secondary">
          {/* {whitelabels && (
            <div className={styles.row}>
              <span>Whitelabel:</span>
              <CustomReactSelect
                placeholder="Select..."
                options={whitelabels}
                value={whitelabel}
                onChange={(item) => setWhitelabel(item)}
              />
            </div>
          )} */}

          {renderFields()}
          <Row>
            <Col md="3"></Col>
            <Col md="4">
              <Button
                style={{
                  width: "100%",
                  margin: "0 auto",
                  borderRadius: "30px",
                  backgroundColor: "light-purple !important",
                }}
                onClick={handleCreateClicked}
                disabled={totalParticipants === 0}
              >
                {renderCreateContent()}
              </Button>
            </Col>
            <Col md="4">
              <Button
                style={{
                  width: "100%",
                  margin: "0 auto",
                  borderRadius: "30px",
                  backgroundColor: "light-purple !important",
                }}
                disabled={loading}
                //   onClick={() => onClose(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Card>
      </Row>
    </div>
  );
}

CreateNewGroupModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateNewGroupModal;
