import styles from "./index.module.scss";
import { ReactComponent as TrashSvg } from "../../svgs/trash.svg"
import { toast } from 'react-toastify'
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Row,
    Button,
    Col,
} from "reactstrap";
import { CustomText } from "@dreamvps/dream-ui/dist";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";

function AddAgentModal({ isOpen, onClose, agent, deleteIcon, onDelete }) {
    const ajax = useAjax();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [department, setDepartment] = useState("");
    const [title, setTitle] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            if (agent) {
                setFirstName(agent.first_name);
                setLastName(agent.last_name);
                setDepartment(agent.department);
                setTitle(agent.title);
                setEmail(agent.email);
                setPhoneNumber(agent.phone_number);
            } else {
                setFirstName("");
                setLastName("");
                setDepartment("");
                setTitle("");
                setEmail('');
                setPhoneNumber('');
            }

            setError(null);
            setLoading(false);
        }
    }, [agent, isOpen]);

    async function handleAddClicked() {
        const _firstName = firstName.trim();
        const _lastName = lastName.trim();
        const _department = department.trim();
        const _title = title.trim();
        const _email = email.trim();
        const _phoneNumber = phoneNumber.trim();

        setError(null);

        if (!_firstName || !_lastName || !_department || !_title || !_phoneNumber || !_email) {
            return setError("Please fill all fields");
        }

        setLoading(true);

        try {
            const data = await ajax(agent ? "/agents/edit" : "/agents/add", {
                firstName: _firstName,
                lastName: _lastName,
                department: _department,
                title: _title,
                email: _email,
                phone_number: _phoneNumber,
                agentID: agent?.id,
            });

            if (data.result === 'error') {
                throw new Error(data.message);
            }
            toast.success(agent ? "Agent updated successfully" : "Agent added successfully");
            setLoading(false);
            onClose(true);
        } catch (err) {
            toast.error(err.message)
            setLoading(false);
        }
    }

    return (
        <div
            style={{
                marginTop: "50px",
                margin: "12.75rem auto !important",
            }}
        >
            <Modal isOpen={isOpen} zIndex={1000}>
                <ModalHeader>
                    {deleteIcon && agent ? <TrashSvg style={{ position: 'absolute', left: '20px', cursor: 'pointer' }} onClick={() => onDelete(agent)} /> : null}
                    {agent ? `Edit ${agent.first_name}` : "Add new agent"}
                </ModalHeader>
                <ModalBody>
                    <div className={styles.row}>
                        <span>First name</span>
                        <CustomText
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className={styles.row}>
                        <span>Last name</span>
                        <CustomText
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                    <div className={styles.row}>
                        <span>Department</span>
                        <CustomText
                            value={department}
                            onChange={(e) => setDepartment(e.target.value)}
                        />
                    </div>
                    <div className={styles.row}>
                        <span>Title</span>
                        <CustomText
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className={styles.row}>
                        <span>Email</span>
                        <CustomText
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className={styles.row}>
                        <span>Phone Number</span>
                        <CustomText
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>
                    {error && <div className="error">{error}</div>}
                </ModalBody>
                <ModalFooter>
                    <Row style={{ width: "40%", marginBottom: "10px" }}>
                        <Col style={{ marginRight: "20px" }}>
                            <Button
                                style={{
                                    width: "150%",
                                    margin: "0 auto",
                                    borderRadius: "30px",
                                }}
                                disabled={loading}
                                onClick={handleAddClicked}
                            >
                                {agent ? "Edit" : "Add"}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                style={{
                                    width: "150%",
                                    margin: "0 auto",
                                    borderRadius: "30px",
                                }}
                                disabled={loading}
                                onClick={() => onClose(false)}
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </div>
    );
}

AddAgentModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    agent: PropTypes.object,
    deleteIcon: PropTypes.bool,
    onDelete: PropTypes.func
};

export default AddAgentModal;
