import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Group, GroupAdd } from "@mui/icons-material";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";
import Logo from "../../asset/Images/Logo.png";

const drawerWidth = 300;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const MenuArray = [
    {
        text: "Admin Management",
        url: "/admin/usermanagement",
        image: false,
        tag: <Group />,

    },
    {
        text: "Agents",
        url: "/admin/agents",
        image: false,
        tag: <GroupAdd />
    },
    {
        text: "Groups",
        image: true,
        imageUrl: '/images/privacy-policy.png',
        url: '/admin/policy'
    },
    {
        text: "Analytics",
        image: true,
        imageUrl: '/images/analytics.png',
        url: '/admin/analytics'
    },
    {
        text: "Bot management",
        image: true,
        imageUrl: '/images/analytics.png',
        url: '/admin/bot-management'
    }
];

// TODO for now
// const MenuArray = [
//   {
//     text: "Groups",
//     url: "/admin/groups",
//   },
//   {
//     text: "User Management",
//     url: "/admin/usermanagement",
//   },
//   {
//     text: "Paired Device",
//     url: "/admin/pairdevice",
//   },
// ];

export default function MiniDrawer() {
    const ajax = useAjax();

    const history = useHistory();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const { user } = useSelector((state) => ({
        user: state.user,
    }));

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    async function handleLogoutAccountClicked(e) {
        e.preventDefault();

        await ajax("/user/logoutAccount");
        if (window.location.pathname === "/") {
            location.href = "/login";
        } else {
            location.href = "/admin";
        }
    }

    function renderMenuIcon(image, url, tag) {
        let ret;
        if (image) {
            ret = <img src={url} height={25} width={25} />
        }
        else {
            ret = tag;
        }

        return (
            <ListItemIcon
                sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                }}
            >
                {ret}
            </ListItemIcon>
        );
    }

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} color="">
                <Toolbar style={{ justifyContent: "space-between" }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: "none" }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Hello {user.first_name}, Here is your dashboard!
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLogoutAccountClicked}
                    >
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <img
                        style={{ width: "144px", height: "40px", marginRight: "92px" }}
                        src={Logo}
                    ></img>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {MenuArray.map((data) => (
                        <ListItemButton
                            onClick={() => history.push(data.url)}
                            key={data.text}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            {renderMenuIcon(data.image, data.imageUrl, data.tag)}

                            <ListItemText
                                primary={data.text}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
            </Box>
        </Box>
    );
}
