import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import user from "./user";
import selectedMessages from "./selected-messages";
import settings from "./settings";
import devices from "./devices";
import media from "./media";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  user,
  devices,
  selectedMessages,
  settings,
  media,
});

export default createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunk))
);
