import "bootstrap/dist/css/bootstrap.min.css";
import "./globals.scss";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import App from "./App";
import ErrorBoundary from "./components/error-boundary";
import mainStore from "./store";
import {
  setAlertModalData,
  setConfirmModalData,
  setPromptModalData,
  setLoaderData,
} from "./store/settings";

window.setAlertModalData = setAlertModalData;
window.setConfirmModalData = setConfirmModalData;
window.setPromptModalData = setPromptModalData;
window.setLoaderData = setLoaderData;
const messages = {
  "general.cancel": "Cancel",
  "general.yes": "Yes",
  "general.no": "No",
  "general.close": "Close",
  "general.select...": "Select...",
  "general.ok": "OK",
};

function renderApp() {
  if (typeof process !== "undefined" && process.env.NODE_ENV === "production") {
    return (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    );
  }

  return <App />;
}

ReactDOM.render(
  <IntlProvider messages={messages} locale="en">
    <Provider store={mainStore}>{renderApp()}</Provider>
  </IntlProvider>,
  document.getElementById("root")
);
