import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { Row, Col, Card, Button } from "reactstrap";
import { BasicTable, Spinner } from "@dreamvps/dream-ui/dist";
import { useAjax, useConfirm, useUser } from "@dreamvps/dream-utils/dist/hooks";
import AddUserModal from "../add-user";
import { ReactComponent as PencilSvg } from "../../svgs/pencil.svg";
import { ReactComponent as TrashSvg } from "../../svgs/trash.svg";

function UsersManagementModal() {
  const ajax = useAjax();
  const currentUser = useUser();
  const confirm = useConfirm();

  const [users, setUsers] = useState(null);

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const getAll = useCallback(async () => {
    const data = await ajax("/user/getAll");

    setUsers(data.users);
  }, [ajax]);

  useEffect(() => {
    getAll();
  }, [getAll]);

  function handleAddClicked() {
    setSelectedUser(null);
    setIsAddUserModalOpen(true);
  }

  async function handleAddUserModalClosed(state) {
    if (state) {
      await getAll();
    }

    setIsAddUserModalOpen(false);
  }

  function handleEditUserClicked(user) {
    setSelectedUser(user);
    setIsAddUserModalOpen(true);
  }

  function handleRemoveUserClicked(user) {
    confirm({
      title: "Remove user",
      message: `Are you sure you want to remove ${user.email}?`,
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/user/delete", { userID: user.id });

        await getAll();
      },
    });
  }

  return (
    <div style={{ width: "80%", marginTop: "100px", marginRight: "100px" }}>
      <Row>
        <div className="px-0">
          <Row className="mb-3">
            <Col
              style={{ fontWeight: "bold" }}
              className="d-flex align-items-center"
            >
              Users management
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                onClick={handleAddClicked}
                className="me-2"
                style={{
                  width: "30%",

                  borderRadius: "30px",
                  backgroundColor: "light-purple !important",
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
        </div>
        <Card body outline color="secondary">
          <BasicTable>
            <thead>
              <tr>
                <th style={{ marginBottom: "20px", fontWeight: "bold" }}>
                  Email
                </th>
                <th style={{ marginBottom: "20px", fontWeight: "bold" }}>
                  First name
                </th>
                <th style={{ marginBottom: "20px", fontWeight: "bold" }}>
                  Last name
                </th>
                {/* <th style={{ marginBottom: "20px", fontWeight: "bold" }}>
                  Role
                </th> */}
                <th style={{ marginBottom: "20px", fontWeight: "bold" }}>
                  Created at
                </th>
                <th style={{ marginBottom: "20px", fontWeight: "bold" }}></th>
              </tr>
            </thead>
            <tbody>
              {!users && (
                <tr>
                  <td colSpan={5}>
                    <Spinner />
                  </td>
                </tr>
              )}

              {users?.length === 0 && (
                <tr>
                  <td colSpan={5}>No data to show</td>
                </tr>
              )}

              {users?.map((user, key) => (
                <tr key={key}>
                  <td>
                    {user.email}
                    {user.id === currentUser.id ? " [You]" : ""}
                  </td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  {/* <td>{user.role}</td> */}
                  <td>{format(new Date(user.created_at), "dd/MM/yy")}</td>
                  <td className={styles.actions}>
                    <PencilSvg onClick={() => handleEditUserClicked(user)} />
                    <TrashSvg onClick={() => handleRemoveUserClicked(user)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        </Card>
      </Row>

      <AddUserModal
        isOpen={isAddUserModalOpen}
        onClose={handleAddUserModalClosed}
        edit={selectedUser}
      />
    </div>
  );
}

UsersManagementModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UsersManagementModal;
