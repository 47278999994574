import produce from "immer";

const SET_MEDIA_FOR_MESSAGE = "SET_MEDIA_FOR_MESSAGE";

export default function reducer(state = {}, action) {
  switch (action.type) {
    case SET_MEDIA_FOR_MESSAGE: {
      return produce(state, (draftState) => {
        draftState[action.messageID] = action.media;
      });
    }

    default:
      return state;
  }
}

export const setMediaForMessage = (messageID, media) => {
  return { type: SET_MEDIA_FOR_MESSAGE, messageID, media };
};
