import styles from "./index.module.scss";
import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import { BasicTable, CustomText, Spinner } from "@dreamvps/dream-ui/dist";
import {
  useAjax,
  useConfirm,
  usePrompt,
} from "@dreamvps/dream-utils/dist/hooks";
import AssignUsersToGroupModal from "../modals/assign-users-to-group";
import SetContentToJoinModal from "../modals/set-content-to-join";
import { getDirection } from "@dreamvps/dream-utils/dist";
import SetWelcomeMessageModal from "../modals/set-welcome-message";
import { ReactComponent as CheckSvg } from "../svgs/check.svg";

export default function Groups() {
  const prompt = usePrompt();
  const confirm = useConfirm();
  const ajax = useAjax();

  const [groups, setGroups] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const [fallbackMessage, setFallbackMessage] = useState("");

  const [isAssignUsersToGroupModalOpen, setIsAssignUsersToGroupModalOpen] =
    useState(false);
  const [isSetContentToJoinModalOpen, setIsSetContentToJoinModalOpen] =
    useState(false);
  const [isSetWelcomeMessageModalOpen, setIsSetWelcomeMessageModalOpen] =
    useState(false);

  const listGroups = useCallback(async () => {
    const data = await ajax("/groups/list");

    setGroups(data.groups);
  }, [ajax]);

  const getFallbackMessage = useCallback(async () => {
    const data = await ajax("/groups/getFallbackMessage");

    setFallbackMessage(data.message);
  }, [ajax]);

  useEffect(() => {
    listGroups();
    getFallbackMessage();
  }, [listGroups, getFallbackMessage]);

  function handleAddClicked() {
    prompt({
      title: "Add new group",
      message: "Please type a name for the new group",
      beforeClose: async (value) => {
        value = value.trim();

        if (!value) {
          return;
        }

        await ajax("/groups/add", { name: value });
        await listGroups();
      },
    });
  }

  function handleRenameGroupClicked(group) {
    prompt({
      title: `Rename ${group.name}`,
      message: "Please type a new name for the group",
      beforeClose: async (value) => {
        value = value.trim();

        if (!value) {
          return;
        }

        await ajax("/groups/rename", { groupID: group.id, name: value });
        await listGroups();
      },
    });
  }

  function handleAssignClicked(group) {
    setSelectedGroup(group);
    setIsAssignUsersToGroupModalOpen(true);
  }

  async function handleAssignUsersToGroupModalClosed() {
    await listGroups();
    setIsAssignUsersToGroupModalOpen(false);
  }

  function handleSetContentToJoinClicked(group) {
    setSelectedGroup(group);
    setIsSetContentToJoinModalOpen(true);
  }

  async function handleSetContentToJoinModalClosed(state) {
    if (state) {
      await listGroups();
    }

    setIsSetContentToJoinModalOpen(false);
  }

  function handleSetWelcomeMessageClicked(group) {
    setSelectedGroup(group);
    setIsSetWelcomeMessageModalOpen(true);
  }

  async function handleSetWelcomeMessageModalClosed(state) {
    if (state) {
      await listGroups();
    }

    setIsSetWelcomeMessageModalOpen(false);
  }

  async function handleSaveFallbackMessageClicked() {
    await ajax("/groups/saveFallbackMessage", { message: fallbackMessage });
  }

  function handleRemoveGroupClicked(group) {
    confirm({
      title: `Remove group ${group.name}`,
      message: "Are you sure you want to remove this group?",
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/groups/remove", { groupID: group.id });
        await listGroups();
      },
    });
  }

  function handleGlobalSetClicked(e) {
    e.preventDefault();

    setSelectedGroup(null);
    setIsSetContentToJoinModalOpen(true);
  }

  function renderFallbackTip() {
    if (!groups) {
      return null;
    }

    const ret = [];

    for (let i = 0; i < groups.length; i++) {
      if (i > 3) {
        ret.push(<div key={i}>And so on...</div>);
        break;
      }

      if (groups[i].contents) {
        const arr = groups[i].contents.split("|");
        const txt = arr.map(
          (item, idx) => (
            <div key={`${i}_${idx}`} className={styles.tab}>
              <b>
                ${groups[i].id}.{idx + 1}
              </b>{" "}
              for <b>{item}</b>
            </div>
          ),
          ""
        );

        ret.push(
          <div key={i}>
            Use <b>${groups[i].id}</b> as a placeholder for{" "}
            <b>{groups[i].name}</b>
            {txt}
          </div>
        );
      }
    }

    if (ret.length === 0) {
      return null;
    }

    return <div className={styles.tip}>{ret}</div>;
  }

  return (
    <div style={{ width: "80%", marginTop: "100px", marginRight: "100px" }}>
      <Row>
        <Col style={{ marginBottom: "20px", fontWeight: "bold" }}>Groups</Col>
        <Card body outline color="secondary">
          <BasicTable>
            <thead>
              <tr>
                <th style={{ marginBottom: "20px", fontWeight: "bold" }}>ID</th>
                <th style={{ marginBottom: "20px", fontWeight: "bold" }}>
                  Name
                </th>
                <th style={{ marginBottom: "20px", fontWeight: "bold" }}>
                  Assigned
                </th>
                <th style={{ marginBottom: "20px", fontWeight: "bold" }}>
                  Content to join{" "}
                  {groups?.length > 0 && (
                    <a href="#" onClick={handleGlobalSetClicked}>
                      Global set
                    </a>
                  )}
                </th>
                <th style={{ marginBottom: "20px", fontWeight: "bold" }}>
                  Welcome message
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!groups && (
                <tr>
                  <th colSpan={10}>
                    <Spinner />
                  </th>
                </tr>
              )}

              {groups?.length === 0 && (
                <tr>
                  <td colSpan={10}>No groups</td>
                </tr>
              )}

              {groups?.map((group, key) => (
                <tr key={key}>
                  <td>#{group.id}</td>
                  <td>{group.name}</td>
                  <td>{group.assigned || 0}</td>
                  <td>{group.total_contents}</td>
                  <td>
                    {group.welcome_message ? (
                      <CheckSvg className={styles.checkSvg} />
                    ) : null}
                  </td>
                  <th>
                    <Button
                      className="adminButton"
                      onClick={() => handleRenameGroupClicked(group)}
                    >
                      Rename group
                    </Button>
                  </th>
                  <th>
                    <Button
                      className="adminButton"
                      onClick={() => handleAssignClicked(group)}
                    >
                      Assign users
                    </Button>
                  </th>
                  <th>
                    <Button
                      className="adminButton"
                      onClick={() => handleSetContentToJoinClicked(group)}
                    >
                      Set content to join
                    </Button>
                  </th>
                  <th>
                    <Button
                      className="adminButton"
                      onClick={() => handleSetWelcomeMessageClicked(group)}
                    >
                      Set welcome message
                    </Button>
                  </th>
                  <th>
                    <Button
                      className="adminButton"
                      onClick={() => handleRemoveGroupClicked(group)}
                    >
                      Remove group
                    </Button>
                  </th>
                </tr>
              ))}
            </tbody>
          </BasicTable>
          <Row style={{ marginBottom: "12px" }}>
            <Col>
              <Button className="adminButton" onClick={handleAddClicked}>
                Add
              </Button>
            </Col>
          </Row>
        </Card>

        <Card body outline color="secondary" style={{ marginTop: "20px" }}>
          <div className={styles.fallbackWrp}>
            <div className={styles.title}>Fallback message</div>
            {renderFallbackTip()}

            <CustomText
              style={{ ...getDirection(fallbackMessage), height: "250px" }}
              type="textarea"
              value={fallbackMessage}
              onChange={(e) => setFallbackMessage(e.target.value)}
            />
            <Button
              className="adminButton"
              onClick={handleSaveFallbackMessageClicked}
            >
              Save
            </Button>
          </div>
        </Card>
      </Row>

      <AssignUsersToGroupModal
        isOpen={isAssignUsersToGroupModalOpen}
        onClose={handleAssignUsersToGroupModalClosed}
        group={selectedGroup}
      />

      <SetContentToJoinModal
        isOpen={isSetContentToJoinModalOpen}
        onClose={handleSetContentToJoinModalClosed}
        group={selectedGroup}
      />

      <SetWelcomeMessageModal
        isOpen={isSetWelcomeMessageModalOpen}
        onClose={handleSetWelcomeMessageModalClosed}
        group={selectedGroup}
      />
    </div>
  );
}
